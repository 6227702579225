exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-3-dconfig-tsx": () => import("./../../../src/pages/projects/3dconfig.tsx" /* webpackChunkName: "component---src-pages-projects-3-dconfig-tsx" */),
  "component---src-pages-projects-dnd-tsx": () => import("./../../../src/pages/projects/dnd.tsx" /* webpackChunkName: "component---src-pages-projects-dnd-tsx" */),
  "component---src-pages-projects-igdb-tsx": () => import("./../../../src/pages/projects/igdb.tsx" /* webpackChunkName: "component---src-pages-projects-igdb-tsx" */),
  "component---src-pages-projects-pokemon-tsx": () => import("./../../../src/pages/projects/pokemon.tsx" /* webpackChunkName: "component---src-pages-projects-pokemon-tsx" */),
  "component---src-pages-projects-portfolio-tsx": () => import("./../../../src/pages/projects/portfolio.tsx" /* webpackChunkName: "component---src-pages-projects-portfolio-tsx" */),
  "component---src-pages-projects-rehydration-tsx": () => import("./../../../src/pages/projects/rehydration.tsx" /* webpackChunkName: "component---src-pages-projects-rehydration-tsx" */),
  "component---src-pages-projects-sncb-tsx": () => import("./../../../src/pages/projects/sncb.tsx" /* webpackChunkName: "component---src-pages-projects-sncb-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-projects-vitalcities-tsx": () => import("./../../../src/pages/projects/vitalcities.tsx" /* webpackChunkName: "component---src-pages-projects-vitalcities-tsx" */)
}

